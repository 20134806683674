import React, { FC } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import TwitterIcon from 'src/components/icons/Twitter.svg'
import FacebookIcon from 'src/components/icons/Facebook.svg'
//import LinkedinIcon from 'src/components/icons/Linkedin.png'
import TwitterIconLink from 'src/components/icons/TwitterIconLink'
import FacebookIconLink from 'src/components/icons/FacebookIconLink'
import LinkedInIconLink from 'src/components/icons/LinkedinIconLink'
import IconInstagram from 'src/components/icons/IconInstagram.svg'

interface IJoinUsIconsProps {}

const JoinUsIcons: FC<IJoinUsIconsProps> = () => {
  const { t } = useTranslation('menu')

  return (
    <div className="text-navyBlueGray text-center flex justify-center items-center">
      <a className="text-center flex justify-center items-center" href="https://www.instagram.com/theifab/" target="_blank" rel="noreferrer">
        <p className="uppercase text-appGreen pr-2.5">{t('Join us!')}</p>
        <img src={IconInstagram}/>
      </a>
      {/* <TwitterIconLink src={TwitterIcon} className="w-5 ml-2" />
      <FacebookIconLink src={FacebookIcon} className="w-5 ml-2" />
      <LinkedInIconLink src={LinkedinIcon} className="w-5 ml-2" /> */}
    </div>
  )
}

export default JoinUsIcons

export interface ILanguage {
  name: string
  code: string
}

const languagesInOrder: ILanguage[] = [
  { name: 'English', code: 'en' },
  // { name: 'Deutch', code: 'de' },
  // { name: 'Spanish', code: 'es' },
  // { name: 'French', code: 'fr' },
]

const lngCodes = languagesInOrder.map((lng) => lng.code)
const defaultLng = languagesInOrder[0]

module.exports = {
  lngCodes,
  defaultLng,
  languages: languagesInOrder,
}

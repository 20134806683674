import React, { FC } from 'react'
import styled from 'styled-components'
import { LazyMotion, m, domAnimation } from 'framer-motion'
import WarningIcon from 'src/images/icons/warning.inline.svg'
import CloseIcon from 'src/images/icons/close.inline.svg'

const StyledMessageContainer = styled.div`
  transform: translateX(-50%);
`

interface IMessageProps {
  children: string | JSX.Element | JSX.Element[]
  isWarning: Boolean
  isShown: Boolean
  onCloseClick: () => any
}

const variants = {
  hidden: {
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
  },
  show: {
    display: 'block',
    opacity: 1,
  },
}

const Message: FC<IMessageProps> = ({
  children,
  isWarning,
  isShown,
  onCloseClick,
}) => (
  <LazyMotion features={domAnimation}>
    <m.div
      variants={variants}
      initial={isShown ? 'show' : 'hidden'}
      animate={isShown ? 'show' : 'hidden'}
      transition={{ duration: 0.5 }}
      className="overflow-hidden"
    >
      <StyledMessageContainer className="container fixed top-24 w-full left-1/2 z-30">
        <div className="flex justify-between p-3 rounded bg-deepDarkBlue text-brightYellow w-full">
          <div className="flex items-center">
            {isWarning ? (
              <WarningIcon
                title="Warning"
                className="w-8 h-8 text-brightYellow stroke-current"
              />
            ) : (
              <></>
            )}
            <div className="ml-4">{children}</div>
          </div>
          <div className="flex items-center">
            <button type="button" onClick={onCloseClick}>
              <CloseIcon className="text-brightYellow fill-current stroke-current w-4 h-4" />
            </button>
          </div>
        </div>
      </StyledMessageContainer>
    </m.div>
  </LazyMotion>
)

export default Message

import React, { FC } from "react";
import sideMenuStore from "src/components/side-menu/side-menu-store";
import ArrowIcon from "src/components/icons/ArrowIcon";
import { useTranslation } from "gatsby-plugin-react-i18next";

interface IBackButtonProps {
  className?: string;
}

const BackButton: FC<IBackButtonProps> = ({ className }) => {
  const { t } = useTranslation("menu");
  const sideMenuState = sideMenuStore();

  const collapseMenu = () => {
    sideMenuState.setSelectedMenuElement(null);

    if (sideMenuState.selectedSubcategory) {
      sideMenuState.setSelectedSubcategory(null);
      return;
    }

    if (sideMenuState.selectedCategory) {
      sideMenuState.setSelectedCategory(null);
    }
  };

  return (
    sideMenuState.sortType !== "asc" && (
      <button
        style={{ height: '3rem', marginLeft: '-5px', width:'230px' }}
        
        type='button'
        className={`text-almostBlackBlue bg-white flex items-center w-full py-4 px-5 border-t border- ${className}`}
        onClick={collapseMenu}>
        <ArrowIcon rotate='left' color='almostBlackBlue' />
        <span className='ml-1 text-almostBlackBlue uppercase font-bold text-base'>
          {t("Go back")}
        </span>
      </button>
     )
  );
};

export default BackButton;

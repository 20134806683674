module.exports = {
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  safelist: [
    "border-almostBlackBlue",
    "border-deepDarkBlue",
    "md:text-lg",
    {
      pattern: /^w-/,
    },
    {
      pattern: /^h-/,
    },
  ],
  theme: {
    extend: {
      spacing: {
        38: "9.5rem",
      },
      minWidth: {
        0: "0",
        "1/4": "25%",
        "1/2": "50%",
        "3/4": "75%",
        full: "100%",
      },
      screens: {
        sm: "640px",
        md: "768px",
        lg: "1024px",
        xl: "1280px",
        "2xl": "1536px",
      },
      container: {
        center: true,
        padding: {
          DEFAULT: "1.5rem",
          sm: "2rem",
          lg: "8rem",
          xl: "16rem",
          "2xl": "21rem",
        },
      },
      zIndex: {
        "-10": "-10",
      },
      skew: {
        170: "170deg",
      },
      fontSize: {
        xxs: ".625rem",
        "2.5xl": "1.75rem",
        "4.5xl": "2.5rem",
      },
      inset: {
        34: "8.5rem",
        42: "10.5rem",
        54: "13.5rem",
      },
      height: {
        0.75: "0.2rem",
        30: "7.5rem",
        4.5: "1.125rem",
      },
      minHeight: {
        "0.87screen": "87vh",
        "0.7screen": "70vh",
      },
      lineHeight: {
        12: "3rem",
      },
      fontFamily: {
        roag: [
          "Roag",
          "ui-sans-serif",
          "system-ui",
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          '"Noto Sans"',
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
          '"Noto Color Emoji"',
        ],
      },
      boxShadow: {
        "3xl": "0 15px 60px -15px rgba(0, 0, 0, 1)",
      },
      colors: {
        black: "#000000",
        deepDarkBlue: "#2C3138",
        grayBlue: "#547385",
        paleBlue: "#DCE6ED",
        veryLightGray: "#D2DEE6",
        lightGray: "#EDF2F6",
        darkGray: "#415E74",
        mainPageBackgroundPlaceholder: "#222d36",
        footballrulesAppBackgroundPlaceholder: "#242d37",
        brightYellow: "#FBFD89",
        darkYellow: "#dde531",
        navyBlueGray: "#EDF2F6",
        navyBlueGrayDark: "#ff6468",
        oceanGray: "#29343E",
        almostBlackBlue: "#01141C",
        darkenYellow: "#E0E16D",
        red: "#ff6468",
        dirtyRed: "#D86767",
        whiteSemiTransparent: "#ffffffde",
        appGreen: "#00FF89",
        appLightGreen: "#7ff9c0",
        appSubCategory: "#547385",
        appElementBackground: "#EDF2F6",
        appGreyLight: "#90A4B0",
        appGreyMedium: "#547385",
        darkBlueGray: "#4f6777",
      },
      backgroundImage: {
        "main-page":
          "url('/images/TheIFAB_LawsLight_V2_Home-Pic-Desktop.jpg')",
        "main-page-mobile":
          "url('/images/TheIFAB_LawsLight_V2b_Home-Pic-Mobile.jpg')",
        "footballrulesapp":
          "url('/images/footballrulesapp/background.webp'), url('/images/footballrulesapp/background.jpg')",
        "footballrulesapp-mobile":
          "url('/images/footballrulesapp/background-mobile.webp'), url('/images/footballrulesapp/background-mobile.png')",
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [],
};

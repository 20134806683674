import { ISeason } from 'src/seasons'
import create from 'zustand'

const { latestSeason } = require('src/seasons')

type LawsStore = {
  selectedSeason: ISeason
  isDeletedTextShown: Boolean

  setSelectedSeason: (season: ISeason) => void
  setIsDeletedTextShown: (isDeleted: boolean) => void
}

const lawsStore = create<LawsStore>((set) => ({
  selectedSeason: latestSeason,
  isDeletedTextShown: false,

  setSelectedSeason: (season) => set(() => ({ selectedSeason: season })),
  setIsDeletedTextShown: (isShown) =>
    set(() => ({ isDeletedTextShown: isShown })),
}))

export default lawsStore

import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import LogoSvg from "src/images/logo/logo-image.svg";
import sideMenuStore from "./side-menu/side-menu-store";
const Logo = () => {
  const store = sideMenuStore();
  function handleOnClick() {
    store.toggleSideMenu();
    store.setIsSideMenuOpen(false);
    if (typeof window !== "undefined") {
      window.location.href = "/";
    }
     
    sessionStorage.clear();
  }
  return (
    <Link to='/' onClick={handleOnClick}>
      <img src={LogoSvg} alt='IFAB' className='h-8' />
    </Link>
  );
};

export default Logo;

import React, { FC } from "react";
import LabeledSwitch from "src/components/ui/LabeledSwitch";
import sideMenuStore from "src/components/side-menu/side-menu-store";
import { isLargeScreen, useIsLargeScreen } from "src/helpers/layout";
import lawsStore from "src/components/laws/laws-store";
import useToggleWebsiteToAnotherSeason, {
  saveSeasonToLocalStorage,
} from "src/helpers/seasons";
import { ISeason } from "src/seasons";

const { previousSeason, latestSeason, seasons } = require("src/seasons");

interface ISeasonSwitchAsCategoryProps {}

const SeasonSwitchAsCategory: FC<ISeasonSwitchAsCategoryProps> = () => {
  const selectedSeason = lawsStore((state) => state.selectedSeason);
  const setSelectedSeason = lawsStore((state) => state.setSelectedSeason);
  const isLarge = useIsLargeScreen();
  const toggleSideMenu = sideMenuStore((state) => state.toggleSideMenu);
  const toggleWebsiteToAnotherSeason = useToggleWebsiteToAnotherSeason();

  const updateSeletedSeason = (season: ISeason) => {
    setSelectedSeason(season);
    saveSeasonToLocalStorage(season);
  };

  const handleChange = () => {
    const isSelectedLatestSeason = selectedSeason.slug === latestSeason.slug;

    toggleWebsiteToAnotherSeason();

    if (isSelectedLatestSeason) {
      updateSeletedSeason(previousSeason);
    } else {
      updateSeletedSeason(latestSeason);
    }

    if (!isLarge) {
      toggleSideMenu();
    }
  };

  const selectedSubcategory = sideMenuStore(
    (state) => state.selectedSubcategory
  );

  if (selectedSubcategory && !isLargeScreen()) {
    return <></>;
  }

  return (
    <div className='bg-grayBlue'>
      <LabeledSwitch
        leftLabel={seasons[0].year}
        rightLabel={seasons[1].year}
        isEnabled={selectedSeason.slug === previousSeason.slug}
        className='px-4'
        onChange={handleChange}
      />
    </div>
  );
};

export default SeasonSwitchAsCategory;

import React, { FC } from "react";
import sideMenuStore from "src/components/side-menu/side-menu-store";
import HamburgerIcon from "src/components/icons/HamburgerIcon.svg";
import HamburgerIconWithMargin from "src/components/icons/HamburgerIconWithMargin.svg";
import ActiveHamburgerIcon from "src/components/icons/Arrow.inline.svg";
import ActiveHamburgerIconWithMargin from "src/components/icons/ArrowIconWithMargin.inline.svg";

interface ISideMenuButtonProps {
  className?: string;
}

const SideMenuButton: FC<ISideMenuButtonProps> = ({ className }) => {
  const toggleSideMenu = sideMenuStore((state) => state.toggleSideMenu);
  const isActive = sideMenuStore((state) => state.isSideMenuOpen);

  const handleClick = () => {
    toggleSideMenu();
  };

  const borderIfActive = isActive
    ? "border-navyBlueGray"
    : "border-transparent";
  const darkBackgroundIfActive = isActive ? "bg-almostBlackBlue" : "";
  const verticalPadding = isActive ? "py-3" : "py-3.5";
  const paddingRight = isActive ? "" : "pr-1.5";

  return (
    <div
      className={`self-stretch flex items-center transition duration-300 linear h-full lg:border-b ${borderIfActive} ${className}`}>
      <button
        onClick={handleClick}
        className={`flex items-center justify-center w-14 sm:hidden ${paddingRight} ${verticalPadding} ${darkBackgroundIfActive}`}
        type='button'>
        <div className='flex justify-center flex-wrap box-border'>
          {!isActive && (
            <img src={HamburgerIcon} alt='Toggle Side Menu' className='h-7' />
          )}
          {isActive && (
            <ActiveHamburgerIcon
              alt='Toggle Side Menu'
              className='h-8 transform rotate-180 lg:rotate-0 text-appGreen'
            />
          )}
        </div>
      </button>
      <button
        onClick={handleClick}
        className={`hidden items-center justify-center w-14 sm:flex ${darkBackgroundIfActive}`}
        type='button'>
        <div className='flex justify-center flex-wrap box-border'>
          {!isActive && (
            <img
              src={HamburgerIconWithMargin}
              alt='Toggle Side Menu'
              className='h-14'
            />
          )}
          {isActive && (
            <ActiveHamburgerIconWithMargin
              alt='Toggle Side Menu'
               className='h-14 transform rotate-180 lg:rotate-0 text-appGreen mt-0'
            />
          )}
        </div>
      </button>
    </div>
  );
};

export default SideMenuButton;

import { useI18next } from 'gatsby-plugin-react-i18next'
import { navigate as gatsbyNavigate } from 'gatsby'
import lawsStore from 'src/components/laws/laws-store'
import { ISeason } from 'src/seasons'
import SINGLE_SEASON_PATHS from 'src/helpers/season-switch-redirects'

const { previousSeason, latestSeason, seasons } = require('src/seasons')

export const savedSeasonKey = 'selectedSeason'

export const saveSeasonToLocalStorage = (savedSeason: ISeason) => {
  localStorage.setItem(savedSeasonKey, JSON.stringify(savedSeason))
}

export const getSeasonFromSlug = () =>
  seasons.find((season) => window.location.pathname.indexOf(season.slug) > 0)

export const getSeasonFromLocalStorage = (): ISeason =>
  JSON.parse(localStorage.getItem(savedSeasonKey))

const useToggleWebsiteToAnotherSeason = () => {
  const selectedSeason = lawsStore((state) => state.selectedSeason)
  const { navigate } = useI18next()

  return () => {
    const currentSeasonSlug = selectedSeason.slug
    const anotherSeasonSlug =
      currentSeasonSlug === latestSeason.slug
        ? previousSeason.slug
        : latestSeason.slug

    const seasonOccursInUrl =
      window.location.pathname.indexOf(currentSeasonSlug) > 0

    const pathToRedirect = SINGLE_SEASON_PATHS.find(
      (redirectObject) => redirectObject.path === window.location.pathname
    )

    if (pathToRedirect) {
      navigate(pathToRedirect.redirect)
      return
    }

    if (seasonOccursInUrl) {
      const updatedPath = window.location.pathname.replace(
        currentSeasonSlug,
        anotherSeasonSlug
      )

      const updatedUrl = updatedPath + window.location.hash

      gatsbyNavigate(updatedUrl)
    }
  }
}

export default useToggleWebsiteToAnotherSeason

import React, { FC } from 'react'
import MenuLink, {
  IMenuLink,
} from 'src/components/side-menu/sub-elements/MenuLink'
import SmallBackButton from 'src/components/side-menu/sub-elements/SmallBackButton'

interface IMenuLinkSubMenuProps {
  upperIndex: number
  menuLinks: IMenuLink[]
  isOpen: Boolean
  title: string
  displayIndex: boolean
}

const MenuLinkSubMenu: FC<IMenuLinkSubMenuProps> = ({
  upperIndex,
  displayIndex,
  menuLinks,
  isOpen,
  title,
}) => {
  const ListOfMenuLinks = (): JSX.Element | JSX.Element[] =>
    menuLinks.map((menuLink) => (
      <MenuLink
        menuLink={menuLink}
        key={menuLink.name}
        upperIndex={upperIndex}
      />
    ))

  return (
    <div
      className={`bg-lime-500 py-1.5 
        ${isOpen ? 'block' : 'hidden'}
      `}
    >
      <SmallBackButton className="mb-3 px-5 bg-appElementBackground" />
      <h4 className="text-appElementBackground px-5 font-bold uppercase flex items-start text-sm mb-2">
        <div className="inlineblock w-5 flex-shrink-0 bg-appElementBackground">
          {displayIndex && upperIndex}
        </div>
        <div style={{backgroundColor:'#EDF2F6'}}>{title}</div>
      </h4>
      <ul className="" style={{backgroundColor:'#EDF2F6'}}>{ListOfMenuLinks()}</ul>
    </div>
  )
}

export default MenuLinkSubMenu

import useMenuStructure, {
  searchByActive,
  searchById,
} from "src/components/side-menu/side-menu-structure";
import { ICategory } from "src/components/side-menu/sub-elements/Category";
import { IMenuElement } from "src/components/side-menu/sub-elements/MenuElement";
import { ISubCategory } from "src/components/side-menu/sub-elements/SubCategory";
import { isBrowser } from "src/helpers/layout";

const { defaultLng } = require("src/i18n");

const getUrlLanguagePrefix = (languageCode: string) => {
  if (languageCode === defaultLng.code) {
    return "";
  }

  return `/${languageCode}`;
};

export const isAnyMenuLinkActiveInsideElement = (
  menuElement: IMenuElement,
  lngCode: string
): boolean => {
  if (!isBrowser) {
    return false;
  }

  const currentPath = window.location.pathname;

  if (`${menuElement.link}` === currentPath) {
    return true;
  }

  return !!(
    menuElement.menuLinks &&
    menuElement.menuLinks.find((menuLink) => {
      if (`${menuLink.link}` === currentPath) {
        return true;
      }

      return false;
    })
  );
};

export const isAnyElementInsideSubcategoryActive = (
  subCategory: ISubCategory,
  lngCode: string
): boolean => {
  if (!isBrowser) {
    return false;
  }
  const currentPath = window.location.pathname;

  if (`${subCategory.link}` === currentPath) {
    return true;
  }

  const anyElementIsActive = () => {
    subCategory.elements &&
      subCategory.elements.find((element) => {
        if (`${element.link}` === currentPath) {
          return true;
        }

        const isAnyMenuLinkActive = isAnyMenuLinkActiveInsideElement(
          element,
          lngCode
        );

        return isAnyMenuLinkActive;
      });

    return !!anyElementIsActive;
  };
};

export const isAnyElementActiveInsideCategory = (
  category: ICategory,
  lngCode: string
): boolean => {
  if (!isBrowser) {
    return false;
  }

  const currentPath = window.location.pathname;
  //const lngPrefix = getUrlLanguagePrefix(lngCode);

  if (`${category.link}` === currentPath) {
    return true;
  }

  const anyElementIsActive =
    category.subcategories &&
    category.subcategories.find((subcategory) => {
      if (`${subcategory.link}` === currentPath) {
        return true;
      }

      const isAnyMenuElementActive = isAnyElementInsideSubcategoryActive(
        subcategory,
        lngCode
      );

      return isAnyMenuElementActive;
    });

  return !!anyElementIsActive;
};

export const CheckMenuActive = (path?: string) => {
  if (path && path.endsWith('/')) {
    path=path.slice(0,-1)
  }
  const _menu = searchByActive(path);
  return _menu;
};

export default {};

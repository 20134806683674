import React, { FC, useEffect, useState } from "react";
import { IMenuItem } from "src/components/side-menu";
import sideMenuStore from "src/components/side-menu/side-menu-store";
import { IMenuLink } from "src/components/side-menu/sub-elements/MenuLink";
import { isLargeScreen } from "src/helpers/layout";
import MenuLinkSubMenu from "src/components/side-menu/sub-elements/MenuLinkSubMenu";
import { useI18next, Link, useTranslation } from "gatsby-plugin-react-i18next";
import { searchById } from "../side-menu-structure";
import { navigate } from "gatsby";
import { registerSideMenuNavigationEvent } from "src/services/GoogleTagManager";
export interface IMenuElement extends IMenuItem {
  menuLinks?: IMenuLink[];
  displayIndex?: boolean;
}

interface IMenuElementProps {
  menuElement: IMenuElement;
  index: number;
  subCategoryId?: string;
}

const MenuElement: FC<IMenuElementProps> = ({
  menuElement,
  index,
  subCategoryId,
}) => {
  const { t } = useTranslation("menu");
  const { navigate } = useI18next();

  const [isActivePage, setIsActivePage] = useState(false);
  const currentLocationPathname = sideMenuStore(
    (state) => state.currentLocationPathname
  );

  const setSelectedMenuElement = sideMenuStore(
    (state) => state.setSelectedMenuElement
  );
  const selectedMenuElement = sideMenuStore(
    (state) => state.selectedMenuElement
  );
  const toggleSideMenu = sideMenuStore((state) => state.toggleSideMenu);
  const menu = sideMenuStore();
  const checkIfPageIsActive = () => {
    const active =
      window?.location?.pathname &&
      window.location.pathname.endsWith(menuElement.link);
    setIsActivePage(active);
    if (active && menuElement.menuLinks && isLargeScreen()) {
      setSelectedMenuElement(menuElement.id);
    }
  };

  useEffect(() => {
    checkIfPageIsActive();
  }, [currentLocationPathname]);

  const [width, setWidth] = useState<number>(0);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const isSelected = selectedMenuElement === menuElement.id;

  const handleClick = (): void => {
    //
    registerSideMenuNavigationEvent(menuElement.link);
    navigate(menuElement.link);

    const selectedParent = searchById(menuElement.link);
    sessionStorage.setItem("id", selectedParent.name);
    sessionStorage.setItem("selected", selectedParent.subcategory.id);
    sessionStorage.setItem(
      "subCategory2",
      selectedParent.subcategory.elements[0].id
    );
    isMobile && menu.setIsSideMenuOpen(false);
  };

  const MenuLinkSubmenuIfDefined = () => {
    if (menuElement.menuLinks) {
      return (
        <MenuLinkSubMenu
          isOpen={isSelected}
          upperIndex={index}
          menuLinks={menuElement.menuLinks}
          title={menuElement.name}
          displayIndex={menuElement.displayIndex}
        />
      );
    }

    return <></>;
  };

  const linkContent = (
    <>
      {menuElement.displayIndex && (
        <span className='w-5 flex-shrink-0'>{index}</span>
      )}
      {t(menuElement.name)}
    </>
  );
  const linkStyles = `appearance-none bg-appElementBackground text-deepDarkBlue cursor-pointer text-sm uppercase py-1.5 px-4 text-left flex items-start w-full lg:text-grayBlue lg:bg-appElementBackground lg:border-0 flex-shrink-0 h-47px
    ${isActivePage ? "font-bold active-page" : ""}
  `;

  const MenuElementLink = () => {
    if (menuElement.menuLinks) {
      return (
        // <button onClick={handleClick} type='button' className={linkStyles}>
        //   {linkContent}
        // </button>
        <></>
      );
    }

    return (
      // <Link
      //   to={menuElement.link}
      //   onClick={handleClick}
      //   type='button'
      //   activeClassName='active-page'
      //   className={linkStyles}>

      //   {linkContent}
      // </Link>

      <button onClick={handleClick} type='button' className={linkStyles}>
        {linkContent}
      </button>

      // <div className={linkStyles} onClick={handleClick}>
      //   {menuElement.name}
      // </div>
    );
  };

  return (
    <li>
      <div className={`${selectedMenuElement ? "hidden" : "block"}`}>
        {MenuElementLink()}
      </div>
      {MenuLinkSubmenuIfDefined()}
    </li>
  );
};

export default MenuElement;

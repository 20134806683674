import React, { FC } from 'react'
import { IMenuItem } from 'src/components/side-menu'
import SideMenuStore from 'src/components/side-menu/side-menu-store'
import { scrollToSectionId } from 'src/helpers/layout'

export interface IMenuLink extends IMenuItem {
  index: number
  displayIndex: boolean
}

interface IMenuLinkProps {
  upperIndex: number
  menuLink: IMenuLink
}

const MenuLink: FC<IMenuLinkProps> = ({ upperIndex, menuLink }) => {
  const currentUrl = SideMenuStore((state) => state.currentUrl)
  const currentAnchor =
    currentUrl && currentUrl.substring(currentUrl.indexOf('#') + 1)
  const menuLinkAnchor = menuLink.link.substring(menuLink.link.indexOf('#') + 1)

  const isActivePage = currentAnchor === menuLinkAnchor

  const getDisplayIndex = () => {
    if (!menuLink.index) {
      return <></>
    }

    if (!menuLink.displayIndex) {
      return menuLink.index
    }

    return (
      <>
        {upperIndex}.{menuLink.index}
      </>
    )
  }

  const handleClick = () => {
    const sectionId = menuLink.link.split('#')[1]

    scrollToSectionId(sectionId)
  }

  const linkStyles = 'text-green flex items-start px-4 py-1 w-full'
  const linkContent = (
    <>
      <div className="mr-5 w-4 flex-shrink-0 text-left leading-5 text-sm">
        {getDisplayIndex()}
      </div>
      <div
        className={`
          text-left leading-5 text-sm
          ${isActivePage ? 'font-bold' : ''}
        `}
      >
        {menuLink.name}
      </div>
    </>
  )

  return (
    <li style={{color:'green'}}>
      <button  className={linkStyles} type="button" onClick={handleClick}>
        {linkContent}
      </button>
    </li>
  )
}

export default MenuLink

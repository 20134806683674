// i18next-extract-mark-ns-start menu

import React, {
  FC,
  useEffect,
  useContext,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import CategoriesContainer from "src/components/side-menu/CategoriesContainer";
import sideMenuStore from "src/components/side-menu/side-menu-store";
import styled from "styled-components";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { isLargeScreen } from "src/helpers/layout";
import tw from "twin.macro";
import {
  useTranslation,
  I18nextContext,
  Link,
} from "gatsby-plugin-react-i18next";
// eslint-disable-next-line import/no-unresolved
import { globalHistory } from "@reach/router";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "src/../tailwind.config";
import { SearcContainer as SearchContainer } from "./styles";
import LabeledSwitch from "../ui/LabeledSwitch";
import SearchIcon from "../../images/icons/search.svg";
import CloseIcon from "../../images/icons/Icon-Close.svg";
import { registersSideMenuSearchEvent } from "src/services/GoogleTagManager";
import shallow from 'zustand/shallow';
export interface IMenuItem {
  name: string;
  id: string;
  isActive?: boolean;
  link?: string;
  tag?: string[];
}

interface ISideMenuProps {
  searchString?: string;
}

const tailwind = resolveConfig(tailwindConfig);
const SearchImage = styled.div`
  background-color: #edf2f6;
  cursor: pointer;
  width: 100px;
  @media (min-width: 13.7rem) {
    width: 178px;
  }
  @media (min-width: ${tailwind.theme.screens.lg}) {
    img {
      margin-left: 30px;
    }
  }
`;
const Search = styled.input.attrs({ type: "text" })`
  height: 47px;
  width: 290px;
  background-size: 25px 25px;
  background-repeat: no-repeat;
  padding-right: 20px;
  padding-left: 10px;
  background-color: #edf2f6;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  &::placeholder {
    padding-left: 5px;
    font-weight: bold;
    color: #000;
  }

  &:focus {
    &::placeholder {
      visibility: hidden;
    }
    &::-webkit-input-placeholder {
      visibility: hidden;
    }
  }

  @media (min-width: 13.7rem) {
    width: 178px;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  @media (min-width: ${tailwind.theme.screens.lg}) {
    width: 520px;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
`;

const SearchBackground = styled.div`
  background-color: #fff;
  height: 47px;
`;
const CategoryFilter = styled.div`
  display: flex;
  margin-left: 15px;
  label {
    margin-top: 3px;
    font-weight: bold;
  }
  div {
    padding-top: 1.5px;
  }
  button {
    padding-top: 2.5px;
  }
  @media (min-width: 13.7rem) {
    height: 40px;
    padding-top: 1px;
  }
  @media (min-width: ${tailwind.theme.screens.lg}) {
    height: 50px;
    padding-top: 2px;
  }
`;
const CategoryContainer = styled.div`
  height: 47px;
  background-color: #90a4b0;
  @media (min-width: 13.7rem) {
    height: 47px;
    padding-top: 8px;
  }
`;
const MenuNav = styled(m.nav)`
  width: 13.7rem;
  overflow: hidden;
  ${tw`fixed`}
  height:50%;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  @media (min-width: ${tailwind.theme.screens.lg}) {
    width: 18rem;
    height: 100%;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
`;

export const SHOW_UPPER_ELEMENT_ON_MOBILE = true;

const SideMenu: FC<ISideMenuProps> = ({ searchString }: ISideMenuProps) => {
  const [
    selectedCategory,
    selectedSubcategory, 
    sortType,
    setIsCloseButtonClicked,
    setCurrentLocationPathname,
  ] = sideMenuStore(
    (state) => [
      state.selectedCategory,
      state.selectedSubcategory, 
      state.sortType,
      state.setIsCloseButtonClicked,
      state.setCurrentLocationPathname,
    ],
    shallow,
  );
  const [userAgent, setUserAgent] = useState("");
  const SearchInput = useRef<HTMLInputElement>(null);
  const SearchInputIcon = useRef<HTMLImageElement>(null);
  const [icon, setIcon] = useState(SearchIcon);
  const { t } = useTranslation("menu");
  const [search, setSearch] = useState<string>();
  const [passSearch, setPassSearch] = useState<string>("");
  const [filter, setFilter] = useState<string>("asc");
  const isSideMenuOpen = sideMenuStore((state) => state.isSideMenuOpen);

  const setDynamicStyles = () => {
    const sideMenu = document.getElementById("side-menu");
    const scrollTop = window.scrollY;
    const header = document.getElementById("header");
    const headerContainer = document.getElementById("header-container");
    const headerHeight = header.offsetHeight;
    const headerOffset = parseInt(headerContainer.style.top, 10) || 0;
    const offsetTop = Math.max(headerHeight + headerOffset, 0);

    const bodyHeight = document.querySelector("body").clientHeight;
    const windowHeight = window.innerHeight;
    const scrollableBottomSpace = bodyHeight - (scrollTop + windowHeight);

    const stickyFooterHeight = document.getElementById("sticky-footer")
      .offsetHeight;
    const footerHeight = document.getElementById("footer").offsetHeight;

    const largeScreenBottomOffset = Math.max(
      footerHeight + stickyFooterHeight - scrollableBottomSpace,
      stickyFooterHeight
    );

    const getBottomPosition = () => {
      if (!isLargeScreen()) {
        return "0";
      }
      return largeScreenBottomOffset;
    };

    sideMenu.style.bottom = `${getBottomPosition()}px`;
    sideMenu.style.top = `${offsetTop}px`;
    sideMenu.style.height = `auto`;
  };

  const navVariants = () => ({
    open: {
      opacity: 1,
      display: "block",
      x: 0,
    },
    closed: {
      opacity: 0,
      x: isLargeScreen() ? "-100%" : "100%",
      transitionEnd: {
        display: "none",
      },
    },
  });

  const updateLayoutDynamicValues = () => {
    /**
     * setTimeout is used to delay the execution of this method.
     * It needs to be fired after updating the position of the header in Header.tsx -> showHeaderWhenScrollingUp
     * I believe it's a temporary solution
     * TODO: remove setTimeout and prepare some more meaningfull solution.
     */
    setTimeout(() => {
      setDynamicStyles();
    });
  };
  const [width, setWidth] = useState<number>(typeof window !== "undefined"?window.innerWidth:0);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [typeof window !== "undefined" ? window.innerWidth:0]);

  const isMobile = width <= 768;

  useLayoutEffect(() => {

    let userAgent = navigator.userAgent;
    let browserName;
    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "firefox";
      if (SearchInput.current) {
        SearchInput.current.style.paddingLeft = "17px";
        SearchInputIcon.current.style.marginLeft = "16px";
      }
      if (isMobile) {
        SearchInputIcon.current.style.marginLeft = "1px";
      }
    } else if (userAgent.match(/safari/i)) {
      browserName = "safari";
      if (SearchInputIcon.current) {
        if (isMobile) {
          SearchInputIcon.current.style.marginLeft = "1px";
        }
        else {
          SearchInputIcon.current.style.marginLeft = "19px";
          
        }
      }
    } else if (userAgent.match(/opr\//i)) {
      browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "edge";
    } else {
      browserName = "No browser detection";
    }
    setUserAgent(browserName);
  }, [typeof window !== "undefined" ? window.navigator.userAgent:'0',isMobile]);

  useEffect(() => {
    updateLayoutDynamicValues();
    globalHistory.listen(({ location, action }) => {
      if (action === "PUSH" || action === "POP") {
        setCurrentLocationPathname(location.pathname);
      }
    });

    window.addEventListener("resize", updateLayoutDynamicValues);
    window.addEventListener("scroll", updateLayoutDynamicValues);
    return () => {
      window.removeEventListener("resize", updateLayoutDynamicValues);
      window.removeEventListener("scroll", updateLayoutDynamicValues);
    };
  }, []);

  const { language, defaultLanguage } = useContext(I18nextContext);
  const languageSuffix = language === defaultLanguage ? "/" : `/${language}/`;

  const onCategoriesSwitch = () => {
    if (filter === "asc") {
      setFilter("desc");
    } else {
      setFilter("asc");
    }
  };

  const handleSearch = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      registersSideMenuSearchEvent(search);
      setPassSearch(search);
      setIcon(CloseIcon);
    }
  };

  const onSearchFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.currentTarget.placeholder = "";
    if (SearchInput.current && !isMobile) {
      SearchInput.current.style.marginLeft = "5px"
      SearchInput.current.style.width="500px"
     // SearchInput.current.style.paddingLeft = "16px";
      if (userAgent === "safari" || userAgent === "firefox")
        if (!isMobile) {
          
          SearchInputIcon.current.style.marginLeft = "14px";
        }
        else {
          SearchInputIcon.current.style.marginRight = "1px";
      }
    }
    else {
      SearchInput.current.style.marginLeft = "5px"
      SearchInput.current.style.width="173px"
    }
  };

  const onSearchImageClick = () => {
    if (search && icon === SearchIcon) {
      setIcon(CloseIcon);
      registersSideMenuSearchEvent(search);
      setPassSearch(search);
    } else {
      setIcon(SearchIcon);
      setIsCloseButtonClicked(true);
      setPassSearch("");
      setSearch("");
    }
  };
  const menu = () => (
    <LazyMotion features={domAnimation}>
      <MenuNav
        id='side-menu'
        animate={isSideMenuOpen ? "open" : "closed"}
        initial={isSideMenuOpen ? navVariants().open : navVariants().closed}
        variants={navVariants()}
        transition={{ type: "Inertia", stiffness: 50 }}
        style={{
          bottom: "245px",
          top: "57px",
        }}
        className='absolute flex-col items-between content-between lg:fixed right-0 bg-almostBlackBlue lg:right-auto lg:left-0 shadow-2xl z-20 border-grayBlue border-b h-auto'>
        <div
          className="flex lg:block h-full flex-col overflow-x-hidden overflow-y-auto">
          <SearchContainer>
            <Search
              placeholder='SEARCH'
              onKeyPress={handleSearch}
              required
              value={search}
              onFocus={onSearchFocus}
              onChange={(e) => setSearch(e.target.value)}
              ref={SearchInput}
            />
            <SearchImage>
              <img
                onClick={onSearchImageClick}
                src={icon}
                ref={SearchInputIcon}
                style={{
                  width: "30px",
                  marginTop: "8px",
                  marginRight: "20px",
                }}
              />
            </SearchImage>
          </SearchContainer>

          {!passSearch && (
            <CategoryContainer>
              <CategoryFilter>
                <label>CATEGORIES</label>{" "}
                <div id="categories-switch-container">
                  <LabeledSwitch
                    //  isEnabled={filter === "asc" ? false : true}
                    onChange={onCategoriesSwitch}
                    rightLabel='A-Z'
                    setSortName={onCategoriesSwitch}
                  />
                </div>
              </CategoryFilter>
            </CategoryContainer>
          )}
          <div className="flex flex-col h-full lg:h-auto justify-between">
            <div>
              <CategoriesContainer searchString={passSearch} sort={filter} />
            </div>
            <div className='b-0 l-0 r-0 absolute h-1 bg-navyBlueGray' />
            <div className="lg:hidden text-appGreen uppercase text-sm">
              <div className='py-3.5 px-4 border-t border-appGreen'>
                <Link to="/footballrulesapp/">{t('Download mobile app')}</Link>
              </div>
              <div className='py-3.5 px-4 border-t border-appGreen'>
                <a href="https://www.theifab.com/">THEIFAB.COM</a>
              </div>
            </div>
          </div>
        </div>
      </MenuNav>
    </LazyMotion>
  );

  return menu();
};

export default SideMenu;

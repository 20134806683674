export const registersSideMenuSearchEvent = (query: string) => {
  if (process.env.NODE_ENV === `development`) {
    return;
  }

  dataLayer.push({ event: "side-menu-search", inputValue: query });
};

export const registerSideMenuNavigationEvent = (link: string) => {
  const sideMenuModeSwitchButtonDot = document.querySelector(
    "#categories-switch-container button span"
  ) as HTMLElement;

  const isButtonSwitched = sideMenuModeSwitchButtonDot.classList.contains(
    "translate-x-5"
  );

  const sideMenuMode = isButtonSwitched ? "A-Z" : "Categories";

  if (process.env.NODE_ENV === `development`) {
    return;
  }

  dataLayer.push({
    event: "side-menu-navigation",
    nextPageLink: link,
    sideMenuMode,
  });
};

import { getLocaleUrlPrefix } from 'src/helpers/page-builders'

const { languages } = require('src/i18n')

interface IRedirect {
  path: string
  redirect: string
}

const generateRedirectsForAllLanguages = (
  path: string,
  redirect: string
): IRedirect[] =>
  languages.map(({ code }) => {
    const lngPrefix = getLocaleUrlPrefix(code)
    const lngPrefixWithSlash =
      lngPrefix.length > 0 ? `/${lngPrefix}` : lngPrefix

    return {
      path: `${lngPrefixWithSlash}${path}`,
      redirect,
    }
  })

const getFifaQualityProgrammeRedirects = (): IRedirect[] =>
  generateRedirectsForAllLanguages(
    '/laws/latest/fifa-quality-programme/',
    '/laws/2020-21/about-the-laws/'
  )

const getModificationsToTheLawsRedirectes = () =>
  generateRedirectsForAllLanguages(
    '/laws/2020-21/modifications-to-the-laws/',
    '/laws/latest/notes-and-modifications/'
  )

const getNotesToTheLawsOfTheGameRedirectes = () =>
  generateRedirectsForAllLanguages(
    '/laws/2020-21/notes-on-the-laws-of-the-game/',
    '/laws/latest/notes-and-modifications/'
  )

const getNotesAndModificationsRedirectes = () =>
  generateRedirectsForAllLanguages(
    '/laws/latest/notes-and-modifications/',
    '/laws/2020-21/notes-on-the-laws-of-the-game/'
  )

const getEditorialChangesRedirects = (): IRedirect[] =>
  generateRedirectsForAllLanguages(
    '/laws/2020-21/editorial-changes/',
    '/laws/latest/about-the-laws/'
  )

const SINGLE_SEASON_PATHS: IRedirect[] = [
  ...getFifaQualityProgrammeRedirects(),
  ...getModificationsToTheLawsRedirectes(),
  ...getNotesToTheLawsOfTheGameRedirectes(),
  ...getNotesAndModificationsRedirectes(),
  ...getEditorialChangesRedirects(),
]

export default SINGLE_SEASON_PATHS

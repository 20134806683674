import styled, { createGlobalStyle } from "styled-components";
import RoagBoldItalic from "../../../static/fonts/Roag-BoldItalic.woff2";
import SearchIcon from "../../images/icons/search.svg";
import tw from "twin.macro";

const CustomStyle = createGlobalStyle`

& input {
    -webkit-appearance: none;
    -webkit-border-radius:0; 
    border-radius:0;
}



`;

export const MainText = styled.div``;
export const CategoryFilter = styled.div`
  display: flex;
  margin-left: 15px;
  padding-top: 5px;
  label {
    margin-top: 8px;
    font-weight: bold;
  }
`;

export const SearcContainer = styled.div`
  display: flex;
  background-color: #edf2f6;
  height:47px;
`;
// export const SearchImage = styled.div`
//   background-color: #edf2f6;
//   cursor: pointer;
//   width:100px;
// `;
export default CustomStyle;

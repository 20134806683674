// i18next-extract-mark-ns-start laws

import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import React, { FC } from 'react'
import ArrowIcon from 'src/components/icons/ArrowIcon'

export interface ILawsNavigatorProps {
  className?: string
  chapterIndex: number
  previousChapterPath: string
  nextChapterPath: string
}

const LawsNavigator: FC<ILawsNavigatorProps> = ({
  className,
  previousChapterPath,
  nextChapterPath,
  chapterIndex,
}) => {
  const { t } = useTranslation('laws')
  const lawLabel = t('Law')

  const previousLawButton = () => {
    if (!previousChapterPath) {
      return <div />
    }

    return (
      <>
        <Link to={previousChapterPath} className="flex items-center">
          <ArrowIcon rotate="left" />
          <span className="text-navyBlueGray ml-2 uppercase">
            {lawLabel} {chapterIndex - 1}
          </span>
        </Link>
      </>
    )
  }

  const nextLawButton = () => {
    if (!nextChapterPath) {
      return <div />
    }

    return (
      <>
        <Link to={nextChapterPath} className="flex items-center">
          <span className="text-navyBlueGray mr-2 uppercase">
            {lawLabel} {chapterIndex + 1}
          </span>
          <ArrowIcon />
        </Link>
      </>
    )
  }

  return (
    <>
      <div
        className={`bg-almostBlackBlue py-2 px-4 flex items-center justify-between relative z-10 ${className}`}
      >
        {previousLawButton()}
        {nextLawButton()}
      </div>
    </>
  )
}

export default LawsNavigator

import create from "zustand";

type SideMenuStore = {
  isSideMenuOpen: boolean;
  selectedCategory: string | null | true;
  currentUrl: string | null;
  currentLocationPathname: string | null;
  selectedSubcategory: string | null;
  selectedMenuElement: string | null;
  sortType: string | null;
  selectedSortedItem: string | null;
  isCloseButtonClicked: boolean;

  toggleSideMenu: () => void;
  setIsSideMenuOpen: (value: boolean) => void;
  setCurrentAnchor: (url: string) => void;
  setCurrentLocationPathname: (url: string) => void;
  setSelectedCategory: (category: string | null | true) => void;
  setSelectedSubcategory: (subcategory: string | null) => void;
  setSelectedMenuElement: (selectedMenuElement: string | null) => void;
  setSortType: (sortType: string) => void;
  setSelectedSortedItem: (selectedSortedItem: string) => void;
  setIsCloseButtonClicked: (value: boolean) => void;
};

const sideMenuStore = create<SideMenuStore>((set) => ({
  isSideMenuOpen: false,
  currentUrl: null,
  selectedCategory: null,
  selectedSubcategory: null,
  selectedMenuElement: null,
  currentLocationPathname: null,
  sortType: null,
  selectedSortedItem: null,
  isCloseButtonClicked: false,

  toggleSideMenu: () =>
    set((state) => ({ isSideMenuOpen: !state.isSideMenuOpen })),
  setIsSideMenuOpen: (value) => set(() => ({ isSideMenuOpen: value })),
  setSelectedCategory: (category) =>
    set(() => ({ selectedCategory: category })),
  setCurrentAnchor: (url) => set(() => ({ currentUrl: url })),
  setCurrentLocationPathname: (pathname) =>
    set(() => ({ currentLocationPathname: pathname })),
  setSelectedSubcategory: (subcategory) =>
    set(() => ({ selectedSubcategory: subcategory })),
  setSelectedMenuElement: (menuElement) =>
    set(() => ({ selectedMenuElement: menuElement })),
  setSortType: (sortType) => {
    set(() => ({
      sortType: sortType,
    }));
  },
  setSelectedSortedItem: (selectedItem) => {
    set(() => ({
      selectedSortedItem: selectedItem,
    }));
  },
  setIsCloseButtonClicked: (value) => {
    set(() => ({
      isCloseButtonClicked: value,
    }));
  },
}));

export default sideMenuStore;

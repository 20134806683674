import React, { FC, useMemo, useState } from "react";
import Category, {
  ICategory,
} from "src/components/side-menu/sub-elements/Category";
import BackButton from "src/components/side-menu/BackButton";
import sideMenuStore from "src/components/side-menu/side-menu-store";
import HeightAnimation from "src/components/layout/HeightAnimation";
import useMenuStructure, {
  useSortMenuStructure,
} from "src/components/side-menu/side-menu-structure";
import lawsStore from "src/components/laws/laws-store";
import { isMobile } from "react-device-detect";

interface ICategoriesContainerProps {
  searchString?: string;
  sort?: string;
}
const mapCategoriesToComponents = (
  categories: ICategory[],
  searchString?: string,
): JSX.Element[] =>
  categories.map((category) => (
    <Category
      category={category}
      key={category.name}
      allCategories={categories}
      searchString={searchString}
    />
  ));

const CategoriesContainer: FC<ICategoriesContainerProps> = ({
  searchString,
  sort,
}: ICategoriesContainerProps) => {
  //

  const menuStructure = useMenuStructure(searchString);
  const [categories, setCategories] = useState(menuStructure);
  const selectedCategory = sideMenuStore((state) => state.selectedCategory);
  const selectedSeason = lawsStore((state) => state.selectedSeason);
  const storeSelectedSort = sideMenuStore((state) => state.sortType);
  const toggleSideMenu = sideMenuStore((state) => state.toggleSideMenu);
  const store = sideMenuStore();
  useMemo(() => {
    if (storeSelectedSort && storeSelectedSort === "asc" && !searchString) {
      setCategories([]);
      SortMenu();
      return;
    }
    if (storeSelectedSort && storeSelectedSort === "asc" && searchString) {
      SearchByCategories();
    }
    if (storeSelectedSort && storeSelectedSort === "desc" && !searchString) {
      setCategories([]);
      setCategories(menuStructure);
      return;
    }
    if (storeSelectedSort && storeSelectedSort === "desc" && searchString) {
      setCategories(menuStructure);
      return;
    }
    if (!storeSelectedSort && searchString) {
      setCategories(menuStructure);
      return;
    }
    if (!storeSelectedSort && !searchString) {
      setCategories([]);
      setCategories(menuStructure);
      return;
    }
    if (!storeSelectedSort && !searchString && !window?.location.pathname) {
      setCategories(menuStructure);
      return;
    }
    if (storeSelectedSort && storeSelectedSort === "asc" && isMobile) {
      store.setSelectedCategory(null);
      store.setSelectedMenuElement(null);
      SortMenu();
    }

    if (typeof window !== "undefined" && !window.location.pathname) {
      sessionStorage.clear();
    }
  }, [searchString, sort]);

  function SortMenu() {
    const sortedMenu = useSortMenuStructure(menuStructure);
    setCategories(sortedMenu);
  }
  function SearchByCategories() {
    const sortedMenu = useSortMenuStructure(menuStructure);
    // const filtered = searchFilteredMenu(sortedMenu, searchString);
    setCategories(sortedMenu);
  }

  return (
    <>
      <div>
        <HeightAnimation isOpen={!!selectedCategory}>
          <BackButton className='lg:hidden' />
        </HeightAnimation>
      </div>
      <ul className='pb-24' style={{ marginTop: "-1px" }}>
        <HeightAnimation isOpen={!selectedCategory}>
          <div className='lg:hidden'>
            {/* <SearchAsMenuCategory iconHeight='h-6' /> */}
          </div>
        </HeightAnimation>
        {categories.length < 1 && (
          <p
            style={{ color: "#90A4B0", marginLeft: "16px", marginTop: "14px" }}>
            NOT FOUND
          </p>
        )}

        {categories.length > 0 &&
          mapCategoriesToComponents(categories, searchString)}
      </ul>
    </>
  );
};

export default CategoriesContainer;

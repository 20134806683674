import React, { FC } from 'react'
import { LazyMotion, m, domAnimation } from 'framer-motion'
import { CSSProperties } from 'styled-components'

interface IHeightAnimationProps {
  children: JSX.Element | JSX.Element[]
  isOpen: Boolean
  className?: string
  style?: CSSProperties
  duration?: number
}

export const heightAnimationVariants = {
  hidden: {
    height: 0,
    transitionEnd: {
      display: 'none',
    },
  },
  show: {
    display: 'block',
    height: 'auto',
  },
}

const HeightAnimation: FC<IHeightAnimationProps> = ({
  children,
  isOpen,
  style,
  className,
  duration,
}) => (
  <LazyMotion features={domAnimation}>
    <m.div
      variants={heightAnimationVariants}
      initial={isOpen ? 'show' : 'hidden'}
      animate={isOpen ? 'show' : 'hidden'}
      transition={{ duration: duration || 0.5 }}
      className={`overflow-hidden ${className || ''}`}
      style={style}
    >
      {children}
    </m.div>
  </LazyMotion>
)

export default HeightAnimation

import React, { FC } from 'react'
import IconInstagram from 'src/components/icons/IconInstagram.svg'

interface IJoinUsIconsMobileProps {
  className?: string
}

const JoinUsIconsMobile: FC<IJoinUsIconsMobileProps> = ({ className }) => (
  <div className={`flex justify-center ${className || ''}`}>
    <div>
      <a className="text-center flex justify-center items-center" href="https://www.instagram.com/theifab/" target="_blank" rel="noreferrer">
        <p className="text-sm text-appGreen uppercase pr-4">{('Join us!')}</p>
        <img src={IconInstagram} alt="Instagram icon"/>
      </a>
    </div>
  </div>
)

export default JoinUsIconsMobile

import React, {
  FC,
  useEffect,
  useState,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";
import SubCategory, {
  ISubCategory,
} from "src/components/side-menu/sub-elements/SubCategory";
import ArrowIcon from "src/components/icons/ArrowIcon";
import {
  IMenuItem,
  SHOW_UPPER_ELEMENT_ON_MOBILE,
} from "src/components/side-menu";
import sideMenuStore from "src/components/side-menu/side-menu-store";
import { useIsLargeScreen } from "src/helpers/layout";
import {
  isAnyElementActiveInsideCategory,
} from "src/helpers/side-menu";
import HeightAnimation from "src/components/layout/HeightAnimation";
import SeasonSwitchAsCategory from "src/components/side-menu/sub-elements/SeasonSwitchAsCategory";
import {
  useI18next,
  useTranslation,
  I18nextContext,
} from "gatsby-plugin-react-i18next";
import { isMobile } from "react-device-detect";
import { registerSideMenuNavigationEvent } from "src/services/GoogleTagManager";
import shallow from 'zustand/shallow';
export interface ICategory extends IMenuItem {
  subcategories?: ISubCategory[];
  isSeasonsSwitch?: boolean;
  SearchString?: string;
}

interface ICategoryProps {
  category: ICategory;
  allCategories: ICategory[];
  className?: string;
  searchString?: string;
}

export const mapSubcategoriesToComponents = (
  { subcategories, isSeasonsSwitch }: ICategory,
  searchString?: string
): JSX.Element[] => {
  const subcategoriesToMap = subcategories || [];
  const mappedSubcategories = subcategoriesToMap.map((subcategory, index) => (
    <SubCategory
      subCategory={subcategory}
      key={subcategory.name}
      index={index}
      searchString={searchString}
    />
  ));

  if (isSeasonsSwitch) {
    return [
      <SeasonSwitchAsCategory key='season-switch' />,
      ...mappedSubcategories,
    ];
  }

  return mappedSubcategories;
};

const Category: FC<ICategoryProps> = ({
  category,
  className,
  allCategories,
  searchString,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [isSubelementActive, setIsSubelementActive] = useState(false);
  const [activeCategoryId, setActiveCategoryId] = useState();
  const { navigate } = useI18next();
  const [
    sortType,
    selectedCategory,
    selectedSubcategory,
    setSelectedCategory,
    setSelectedSubcategory,
    setSelectedMenuElement,
    toggleSideMenu,
  ] = sideMenuStore(
    (state) => [
      state.sortType,
      state.selectedCategory,
      state.selectedSubcategory,
      state.setSelectedCategory,
      state.setSelectedSubcategory,
      state.setSelectedMenuElement,
      state.toggleSideMenu,
    ],
    shallow,
  );
  const isLarge = useIsLargeScreen();
  const isSelected = () => category.id === selectedCategory;
  const { t } = useTranslation("menu");
  const { language } = useContext(I18nextContext);

  useLayoutEffect(() => {
    if (searchString && buttonRef.current) {
      buttonRef.current.click();
    }
    if (sortType === "desc" && buttonRef.current) {
      //  buttonRef.current.click();
    }
    if (sortType === "desc" && linkRef.current) {
      //  linkRef.current.click();
    }
    if (
      window?.location.pathname.split("/")[1] === category.id &&
      window.location.pathname.split("/")[2] !== "players" &&
      window.location.pathname.split("/")[2] !== "captains" &&
      window.location.pathname.split("/")[2] !== "goalkeepers" &&
      window.location.pathname.split("/")[2] !== "extra-person-or-object" &&
      window.location.pathname.split("/")[2] !==
        "substitutes-or-team-officials" &&
      window.location.pathname.split("/")[2] !== "obstruction" &&
      window.location.pathname.split("/")[2] !== "arguing" &&
      window.location.pathname.split("/")[2] !== "swearing" &&
      window.location.pathname.split("/")[2] !== "feinting" &&
      window.location.pathname.split("/")[2] !== "other-offences" &&
      window.location.pathname.split("/")[2] !== "red-card" &&
      window.location.pathname.split("/")[2] !== "simulation"&&
      window.location.pathname.split("/")[2] !== "yellow-card"
    ) {
      handleClick(category);
    }
  }, [isMobile]);

  const clearMenuSelections = () => {
    if (!isLarge) {
      setSelectedSubcategory(null);
      setSelectedCategory(null);
    }
  };

  useEffect(() => {
    document.querySelectorAll("a[hreflang]").forEach((el) => {
      el.addEventListener("click", clearMenuSelections);
    });
    return () => {
      document.querySelectorAll("a[hreflang]").forEach((el) => {
        el.removeEventListener("click", clearMenuSelections);
      });
    };
  });

  const isOtherCategorySelected = (): boolean =>
    selectedCategory && selectedCategory !== category.id;

  const autoSelectCategoryIfActive = () => {
    const isActive = isAnyElementActiveInsideCategory(category, language);

    setIsSubelementActive(isActive);

    if (category.subcategories || category.isSeasonsSwitch) {
      if (isActive) {
        setSelectedCategory(category.id);
        sessionStorage.setItem("id", category.id);
      } else {
        if (
          sessionStorage.getItem("id") &&
          sessionStorage.getItem("id") === category.id
        ) {
          setSelectedCategory(category.id);
        }
      }
    }
  };

  const deselectCategoriesIfNoneIsActive = () => {
    const isAnyActive = allCategories.find((singleCategory) =>
      isAnyElementActiveInsideCategory(singleCategory, language)
    );

    if (!isAnyActive) {
      setSelectedCategory(null);
      setSelectedSubcategory(null);
    }
  };

  const updateIsChildLinkActive = () => {
    autoSelectCategoryIfActive();
    deselectCategoriesIfNoneIsActive();
  };

  const handleClick = ({ id, link }: ISubCategory) => {
    if (!isSelected()) {
      setSelectedSubcategory(null);
      setSelectedMenuElement(null);
    }

    if (category.isSeasonsSwitch) {
      if (!isSelected()) {
        setSelectedCategory(id);
      } else {
        setSelectedCategory(null);
      }
    }

    if (link) {
      setSelectedSubcategory(null);
      if (!category.isSeasonsSwitch) {
        setSelectedCategory(null);
      }

      if (!isLarge) {
        toggleSideMenu();
      }

      registerSideMenuNavigationEvent(link);
      navigate(link);

      return;
    }

    if (isSelected() && isLarge) {
      setSelectedMenuElement(null);
      setSelectedSubcategory(null);
      setSelectedCategory(null);
      return;
    }

    if (category.subcategories) {
      setSelectedMenuElement(null);
      setSelectedSubcategory(null);
      setSelectedCategory(id);
    }
  };

  const SubCategoriesList = () =>
    mapSubcategoriesToComponents(category, searchString);

  const isArrowShown = (): boolean =>
    (!!category.subcategories || category.isSeasonsSwitch) && isLarge;

  const isSubcategorySelected = !!selectedSubcategory;

  const getCategoryShowStatus = () => {
    if (isLarge === null || isLarge || sortType === 'asc') {
      return true;
    }

    if (isSubcategorySelected || isOtherCategorySelected()) {
      return false;
    }

    if (isSelected()) {
      return SHOW_UPPER_ELEMENT_ON_MOBILE;
    }

    return true;
  };

  const getArrowRotate = () => {
    if (isLarge) {
      if (isSelected()) {
        return "top";
      }

      return "bottom";
    }

    return "right";
  };

  const linkStyles = `
    text-white cursor-pointer uppercase font-bold py-3.5 lg:py-4 px-4 text-left flex lg:flex justify-between items-center w-full text-sm lg:text-base border-grayBlue gap-1px leading-1
    ${className || ""}
    ${!isSelected() ? " border-b" : ""}
    
  `;

  const categoryStyles = `
    ${linkStyles}
     ${
       typeof window !== "undefined" &&
       window?.location.pathname.split("/")[1] === category.id
         ? "text-appGreen"
         : ""
     }
     ${
       typeof window !== "undefined" &&
       window?.sessionStorage.getItem("id") &&
       window?.sessionStorage.getItem("id") === category.id
         ? "text-appGreen"
         : ""
     }
    
  `;

  const CategoryLink = () => {
    if (category.link) {
      // return (
      //   // <Link
      //   //   ref={linkRef}
      //   //   onClick={() => handleClick(category)}
      //   //   to={category.link}
      //   //   //activeClassName="active-page text-brightYellow"
      //   //   className={categoryStyles}>
      //   //   {t(category.name)}
      //   //   {isArrowShown() ? <ArrowIcon rotate={getArrowRotate()} /> : ""}
      //   // </Link>
      // );
    }

    return (
      <button
        ref={buttonRef}
        onClick={() => handleClick(category)}
        type='button'
        style={{
          color:
            typeof window !== "undefined" &&
            window?.location.pathname.split("/")[1] === category.id
              ? "#00FF89"
              : "",
          height: '47px',
          marginTop:'1px'
        }}
        className={categoryStyles}>
        {t(category.name)}

        <div style={{ marginLeft: "-5px" }}>
          {isArrowShown() && (!sortType || sortType === "desc") && (
            <ArrowIcon rotate={getArrowRotate()} color="appGreen" />
          )}
        </div>
      </button>
    );
  };
  // || searchString?.length > 0
  // || window?.location.pathname.split("/")[1] === category.id
  return (
    <li id={category.id}>
      <HeightAnimation isOpen={getCategoryShowStatus()}>
        {CategoryLink()}
      </HeightAnimation>
      <HeightAnimation
        isOpen={
          selectedCategory === category.id ||
          searchString?.length > 0 ||
          sortType === "asc"
        }>
        <ul
          style={{
            marginTop: "-1px",
            padding: "0",
            margin: "0",
            listStyle: "none",
          }}>
          {SubCategoriesList()}
        </ul>
      </HeightAnimation>
    </li>
  );
};

export default Category;

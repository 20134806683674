import React, { FC, useState, useEffect } from "react";
import sideMenuStore from "src/components/side-menu/side-menu-store";

interface ISwitchProps {
  isEnabled: boolean;
  onChange: (e) => any;
  className?: string;
  isGrayBackground?: boolean;
  setSortName?: (name: string) => void;
  sortName?: string;
}

const Switch: FC<ISwitchProps> = ({
  isEnabled,
  onChange,
  className,
  isGrayBackground,
  setSortName,
}) => {
  const useSideMenuStore = sideMenuStore();
  const sortSelected = useSideMenuStore.sortType;
  const [filter, setIsFilter] = useState<string>(
    !sortSelected ? "desc" : sortSelected
  );
  const handleChange = () => {
    if (filter === "asc") {
      setIsFilter("desc");
      useSideMenuStore.setSortType("desc");
    } else {
      setIsFilter("asc");
      useSideMenuStore.setSortType("asc");
    }
    setSortName(filter);
  };

  const background = isGrayBackground ? "bg-grayBlue" : "bg-almostBlackBlue";
  return (
    <button
      type='button'
      onClick={handleChange}
      className={`relative inline-flex items-center h-5 rounded-full w-9 ${background} ${className}`}>
      <span
        style={{marginBottom:'2.5px'}}
        className={`${
          filter === "asc" ? "translate-x-5" : "translate-x-1"
        } inline-block w-3 h-3 transform bg-appGreen rounded-full transition ease-in-out duration-200`}
      />
      l
    </button>
  );
};

export default Switch;

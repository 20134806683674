import React, { FC, useEffect, useRef } from "react";
import JoinUsIcons from "src/components/icons/JoinUsIcons";
import {
  useTranslation,
  Link,
} from "gatsby-plugin-react-i18next";

interface IStickyFooterProps {}

const StickyFooter: FC<IStickyFooterProps> = () => {
  const stickyFooterRef = useRef<HTMLInputElement>(null);
  const stickyFooterSpacer = useRef<HTMLInputElement>(null);
  const { t } = useTranslation("menu");

  const updateSpacerHeight = () => {
    const extraOffset = 2;
    stickyFooterSpacer.current.style.height = `${
      stickyFooterRef.current.offsetHeight - extraOffset
    }px`;
  };

  const setFooterPosition = (position: string) => {
    stickyFooterRef.current.style.position = position;
  };

  const updateFooterShift = () => {
    if (typeof window === "undefined") {
      return;
    }

    updateSpacerHeight();

    const footerHeight = document.getElementById("footer").clientHeight;
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const bodyHeight = document.querySelector("body").clientHeight;
    const scrollableBottomSpace = bodyHeight - (scrollTop + windowHeight);

    if (footerHeight < scrollableBottomSpace) {
      setFooterPosition("fixed");
      return;
    }

    setFooterPosition("absolute");
  };

  const updateDynamicLayoutValues = () => {
    updateFooterShift();
  };

  useEffect(() => {
    updateDynamicLayoutValues();

    window.addEventListener("scroll", updateDynamicLayoutValues);
    window.addEventListener("resize", updateDynamicLayoutValues);

    return () => {
      window.removeEventListener("scroll", updateDynamicLayoutValues);
      window.removeEventListener("resize", updateDynamicLayoutValues);
    };
  });

  return (
    <>
      <div
        id='sticky-footer'
        ref={stickyFooterRef}
        className='fixed bottom-0 inset-x-0 z-20'>
        <div
          className='justify-between items-center py-3 bg-almostBlackBlue border-appGreen border-b text-appGreen no-underline uppercase hidden lg:flex'
          >
          <a
            href='https://www.theifab.com/'
            className='ml-5 cursor-pointer'
            >
            THEIFAB.COM
          </a>
          <Link to="/footballrulesapp/" className="ml-5 cursor-pointer">
            {t('Download mobile app')}
          </Link>
          <a
            href='https://www.theifab.com/laws/latest/the-field-of-play/?side-menu-category=laws-of-the-game&side-menu-sub-category=laws-1-17&side-menu-open=true&side-menu-open=true'
            className='ml-5 cursor-pointer'>
            {t("Access complete laws of the game ")}
          </a>
          <div className="mr-5">
            <JoinUsIcons />
          </div>
        </div>
      </div>

      <div ref={stickyFooterSpacer} className='hidden lg:block' />
    </>
  );
};

export default StickyFooter;

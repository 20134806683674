import React, { FC } from 'react'
import Switch from 'src/components/ui/Switch'

interface ILabeledSwitchProps {
  isEnabled?: boolean
  onChange?: () => any
  leftLabel?: string
  rightLabel?: string
  className?: string
  isGrayBackground?: boolean
  noPadding?: boolean
  setSortName?:(name:string)=>void
}

const LabeledSwitch: FC<ILabeledSwitchProps> = ({
  leftLabel,
  rightLabel,
  onChange,
  isEnabled,
  className,
  isGrayBackground,
  noPadding,
  setSortName
}) => (
  <>
    <div
      className={`flex items-center justify-start
        ${noPadding ? '' : 'py-2'}
        ${className}`}
    >
      <div className="leading-none">
        <Switch
          onChange={onChange}
          isEnabled={isEnabled}
          className="mx-4"
          isGrayBackground={isGrayBackground}
          setSortName={setSortName}
        />
      </div>
      <button
        type="button"
        onClick={() => (!isEnabled ? onChange() : () => {})}
        className={`${
          isEnabled
            ? 'text-black-300 font-bold cursor-default'
            : 'text-black-300 cursor-pointer'
        } uppercase text-xl lg:text-base leading-none`}
      >
        
        <p style={{fontSize:'15px',fontWeight:'bold'}}>{rightLabel}</p>
      </button>
    </div>
  </>
)

export default LabeledSwitch

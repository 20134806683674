import React, { FC, useEffect } from 'react'
import Logo from 'src/components/Logo'
import SideMenuButton from 'src/components/side-menu/SideMenuButton'
import FeaturedNews, {
  IFeaturedNewsProps,
} from 'src/components/news/featured/FeaturedNews'
import LawsNavigator, {
  ILawsNavigatorProps,
} from 'src/components/laws/LawsNavigator'

const showHeaderWhenScrollingUp = () => {
  let previousScrollPosition = 0

  return () => {
    const header = document.getElementById('header')
    const headerContainer = document.getElementById('header-container')
    const headerHeight = header.offsetHeight
    const headerPlaceholder = document.getElementById('header-placeholder')
    headerPlaceholder.style.height = `${headerHeight}px`
    headerContainer.style.position = 'fixed'
    const currentHeaderOffset = parseInt(headerContainer.style.top, 10) || 0
    const pixelsScrolled = window.scrollY - previousScrollPosition
    const thisScrollOffset = pixelsScrolled * -1
    const newHeaderOffset = currentHeaderOffset + thisScrollOffset
    const newPositon = Math.min(Math.max(newHeaderOffset, headerHeight * -1), 0)

    if (window.scrollY < 0) {
      headerContainer.style.top = `${newPositon - window.scrollY}px`
    } else {
      headerContainer.style.top = `${newPositon}px`
    }

    previousScrollPosition = window.scrollY
  }
}

interface IHeaderProps {
  featuredNewsItem?: IFeaturedNewsProps
  lawsNavigatorProps?: ILawsNavigatorProps
}

const Header: FC<IHeaderProps> = ({
  featuredNewsItem,
  lawsNavigatorProps,
}): JSX.Element => {
  useEffect(() => {
    const scrollFunction = showHeaderWhenScrollingUp()

    window.addEventListener('scroll', scrollFunction)

    return () => {
      window.removeEventListener('scroll', scrollFunction)
    }
  })
  return (
    <>
      <div id="header-placeholder" />
      <div id="header-container" className="relative left-0 right-0 top-0 z-20">
        <header id="header" className="shadow-lg bg-white">
          <div className="relative mx-auto flex justify-between items-stretch">
            <SideMenuButton className="hidden lg:flex" />
            <div className="px-4 py-3">
              <Logo />
            </div>
            <div className="w-14">
              <SideMenuButton className="lg:hidden" />
            </div>
          </div>
          {lawsNavigatorProps ? (
            <LawsNavigator
              chapterIndex={lawsNavigatorProps.chapterIndex}
              previousChapterPath={lawsNavigatorProps.previousChapterPath}
              nextChapterPath={lawsNavigatorProps.nextChapterPath}
              className="lg:hidden"
            />
          ) : null}
        </header>
        {/* {featuredNewsItem && featuredNewsItem.title ? (
          <FeaturedNews
            title={'hhh'}
            slug={featuredNewsItem.slug}
            date={featuredNewsItem.date}
          />
        ) : null} */}
      </div>
    </>
  )
}

export default Header

import React, {
  FC,
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
} from "react";
import MenuElement, {
  IMenuElement,
} from "src/components/side-menu/sub-elements/MenuElement";
import ArrowIcon from "src/components/icons/ArrowIcon";
import {
  IMenuItem,
  SHOW_UPPER_ELEMENT_ON_MOBILE,
} from "src/components/side-menu";
import sideMenuStore from "src/components/side-menu/side-menu-store";
import { useIsLargeScreen } from "src/helpers/layout";
import HeightAnimation from "src/components/layout/HeightAnimation";
import {
  useI18next,
  useTranslation,
  Link,
  I18nextContext,
} from "gatsby-plugin-react-i18next";
import { isAnyElementInsideSubcategoryActive } from "src/helpers/side-menu";
import { searchById } from "../side-menu-structure";
import { registerSideMenuNavigationEvent } from "src/services/GoogleTagManager";
import shallow from 'zustand/shallow';

export interface ISubCategory extends IMenuItem {
  elements?: IMenuElement[];
}

interface ISubCategoryProps {
  subCategory: ISubCategory;
  index: number;
  searchString?: string;
  link?: string;
  tag?: string[];
}

const SubCategory: FC<ISubCategoryProps> = ({
  subCategory,
  searchString,
  link,
}) => {
  const { t } = useTranslation("menu");
  const [
    currentLocationPathname, 
    sortType, 
    selectedSubcategory, 
    selectedSortedItem,
    selectedMenuElement,
    toggleSideMenu,
    setSelectedSubcategory,
    setSelectedSortedItem,
    setSelectedMenuElement,
  ] = sideMenuStore(
    (state) => [
      state.currentLocationPathname, 
      state.sortType, 
      state.selectedSubcategory, 
      state.selectedSortedItem,
      state.selectedMenuElement,
      state.toggleSideMenu,
      state.setSelectedSubcategory,
      state.setSelectedSortedItem,
      state.setSelectedMenuElement,
    ],
    shallow,
  );

  const { language } = useContext(I18nextContext);
  const [isChildLinkActive, setIsChildLinkActive] = useState(
    isAnyElementInsideSubcategoryActive(subCategory, language)
  );

  const isOtherSubcategorySelected = () =>
    selectedSubcategory && selectedSubcategory !== subCategory.id;

  const autoSelectSubCategoryIfActive = (isActive: boolean) => {
    if (subCategory.elements) {
      if (isActive) {
        setSelectedSubcategory(subCategory.id);
      }
    }
  };

  useLayoutEffect(() => {});

  const updateIsChildLinkActive = () => {
    const isActive = isAnyElementInsideSubcategoryActive(subCategory, language);

    setIsChildLinkActive(isActive);
    autoSelectSubCategoryIfActive(isActive);
  };

  useEffect(() => {
    updateIsChildLinkActive();
  }, [currentLocationPathname]);

  const [width, setWidth] = useState<number>(0);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const { navigate } = useI18next();

  const isLarge = useIsLargeScreen();

  const ListOMenuElements = (): JSX.Element | JSX.Element[] => {
    if (!subCategory.elements) {
      return <></>;
    }

    return subCategory.elements.map((element, index) => (
      <MenuElement menuElement={element} key={element.id} index={index + 1} />
    ));
  };

  const isSelected = () => {
    return subCategory.id === selectedSubcategory;
  };

  const handleClick = (): void => {
    //
    
    if (subCategory.id === 'pdf-by-category') {
      registerSideMenuNavigationEvent("pdf-by-category");
      window.open("/pdf/43623/1730931880-the-ifab_footballrules_category.pdf")
      return;
    }
    if (subCategory.id === 'pdf-a-z') {
      registerSideMenuNavigationEvent("pdf-a-z");
      window.open("/pdf/43623/1730932018-the-ifab_football-rules_a-z.pdf")
      return;
    }
    sessionStorage.setItem("id", window?.location.pathname.split("/")[1]);
    if (subCategory.link) {
      isMobile && !subCategory.elements && toggleSideMenu();
      const getCategoryByLink = searchById(subCategory.link);
      if (getCategoryByLink) {
        sessionStorage.setItem("id", getCategoryByLink.id);
      }
      registerSideMenuNavigationEvent(subCategory.link);
      navigate(subCategory.link);
      if (!isLarge) {
        // toggleSideMenu();
      }
      setSelectedSubcategory(null);
      if (sortType === "asc") {
        setSelectedSortedItem(subCategory.name);
        sessionStorage.setItem("id", window?.location.pathname.split("/")[1]);
      }

      if (subCategory.elements && sessionStorage.getItem("selected")) {
        setSelectedMenuElement(null);
        setSelectedSubcategory(subCategory.id);
        sessionStorage.setItem("id", window?.location.pathname.split("/")[1]);
      }
      if (!selectedSortedItem) {
        setSelectedSortedItem(subCategory.name);
        sessionStorage.setItem("selected", subCategory.id);
      } else {
        sessionStorage.setItem("selected", subCategory.id);
      }
      if (
        sessionStorage.getItem("id") &&
        sessionStorage.getItem("id").toLocaleLowerCase() ===
          window?.location.pathname.split("/")[1].toLocaleLowerCase() &&
        window?.location.pathname.split("/")[2].toLocaleLowerCase() ===
          sessionStorage.getItem("selected").toLocaleLowerCase()
      ) {
      } else {
        sessionStorage.setItem("id", window?.location.pathname.split("/")[1]);
      }
      sessionStorage.setItem("id", window?.location.pathname.split("/")[1]);
    }
    sessionStorage.setItem("id", window?.location.pathname.split("/")[1]);
  };

  const clickOnArrow = () => {
    if (selectedSubcategory) {
      setSelectedSubcategory(null);
      setSelectedMenuElement(null);
      sessionStorage.setItem("id", window?.location.pathname.split("/")[1]);
    } else {
      setSelectedMenuElement(null);
      setSelectedSubcategory(subCategory.id);
      sessionStorage.setItem("id", window?.location.pathname.split("/")[1]);
    }
  };

  const isArrowShown = () => !!subCategory.elements && isLarge;

  const isSubcategoryOpen = () => {
    if (isLarge === null || sortType === 'asc') {
      return true;
    }

    if (!isLarge && isOtherSubcategorySelected()) {
      return false;
    }

    if (!isLarge && isSelected()) {
      return SHOW_UPPER_ELEMENT_ON_MOBILE;
    }

    return true;
  };

  const isSubOpen = () => {
    return (
      (window?.location.pathname === "/participants/players" ||
        window?.location.pathname === "/participants/captains" ||
        window?.location.pathname === "/participants/goalkeepers" ||
        window?.location.pathname === "/game-events/extra-person-or-object" ||
        window?.location.pathname === "/offences-sanctions/obstruction" ||
        window?.location.pathname === "/offences-sanctions/arguing" ||
        window?.location.pathname === "/offences-sanctions/swearing" ||
        window?.location.pathname === "/offences-sanctions/simulation" ||
        window?.location.pathname === "/offences-sanctions/feinting" ||
        window?.location.pathname === "/offences-sanctions/other-offences" ||
        window?.location.pathname === "/offences-sanctions/red-card" ||
        window?.location.pathname === "/offences-sanctions/yellow-card") &&
      isArrowShown()
    );
  };
  const selectedMenuElementClasses = selectedMenuElement
    ? "border-l-8 border-brightYellow"
    : "";

  const linkContent = (
    <>
      {t(subCategory.name)}
      {isArrowShown() ? (
        <div>
          <ArrowIcon
            rotate={isSelected() ? "top" : "bottom"}
            color='appGreen'
          />
        </div>
      ) : (
        ""
      )}
    </>
  );

  const linkStyles = `
     text-sm uppercase py-2.5 px-4 text-left border-t border-appGreen flex justify-between items-center w-full lg:flex
      bg-appSubCategory lg:bg-appSubCategory h-47px leading-1
      ${!isSelected() ? "cursor-pointer" : ""}
    ${
      typeof window !== "undefined" &&
      window.sessionStorage.getItem("selected") === subCategory.id
        ? "text-appGreen font-bold"
        : "text-appGreen"
    }
  `;

  const SubcategoryLink = () => {
    if (subCategory.elements || !subCategory.link) {
      return (
        <button
          type='button'
          onClick={clickOnArrow}
          onKeyPress={clickOnArrow}
          tabIndex={0}
          className={linkStyles}>
          {linkContent}
        </button>
      );
    }

    return (
      <Link
        onClick={handleClick}
        onKeyPress={handleClick}
        to={subCategory.link}
        tabIndex={0}
        className={linkStyles}
        activeClassName={"lg:font-bold text-appGreen active-page"}>
        {linkContent}
      </Link>
    );
  };

  const isSearchOpen = () => {
    return searchString && isArrowShown();
  };

  return (
    <li id={subCategory.id} style={{ marginTop: "-1px" }}>
      {/* <HeightAnimation isOpen={isSubcategoryOpen() || selectedSortedItem===subCategory.id}></HeightAnimation> */}
      <HeightAnimation isOpen={isSubcategoryOpen()}>
        {SubcategoryLink()}
      </HeightAnimation>
      <HeightAnimation isOpen={isSearchOpen() || isSelected()}>
        <ul
          className={`bg-appElementBackground lg:bg-appElementBackground py-2.5 ${selectedMenuElementClasses}`}>
          {ListOMenuElements()}
        </ul>
      </HeightAnimation>
    </li>
  );
};

export default SubCategory;

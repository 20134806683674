import { useEffect, useState } from 'react'
import { theme } from 'src/../tailwind.config'

const IS_MOBILE_FIRST = true

export const isBrowser = typeof window !== 'undefined'

export const isLargeScreen = () => {
  if (!isBrowser) {
    return !IS_MOBILE_FIRST
  }
  return window.innerWidth >= parseInt(theme.extend.screens.lg, 10)
}

export const isMdScreen = () => {
  if (!isBrowser) {
    return !IS_MOBILE_FIRST
  }
  return window.innerWidth >= parseInt(theme.extend.screens.md, 10)
}

export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const useIsLargeScreen = () => {
  const [isLarge, setIsLarge] = useState(null)

  const updateValue = () => {
    setIsLarge(isLargeScreen())
  }

  useEffect(() => {
    updateValue()
    window.addEventListener('resize', updateValue)

    return () => {
      window.removeEventListener('resize', updateValue)
    }
  })

  return isLarge
}

export const useIsMdScreen = () => {
  const [isMd, setIsMd] = useState(null)

  const updateValue = () => {
    setIsMd(isMdScreen())
  }

  useEffect(() => {
    updateValue()
    window.addEventListener('resize', updateValue)

    return () => {
      window.removeEventListener('resize', updateValue)
    }
  })

  return isMd
}

const getHeaderHeight = () => {
  const header = document.getElementById('header')
  return header.offsetHeight
}

const getSectionsOnScreen = (sections) =>
  sections.filter((el) => {
    const rect = el.getBoundingClientRect()
    const isSectionVisible = rect.bottom > 0 && rect.top < window.innerHeight

    return isSectionVisible
  })

const getCurrentSection = (sections) => {
  const headerHeight = getHeaderHeight()

  return sections.find((section) => {
    const safetyOffset = 5
    const CURRENT_SECTION_POINT = headerHeight + safetyOffset
    const rect = section.getBoundingClientRect()

    return (
      rect.top < CURRENT_SECTION_POINT && rect.bottom > CURRENT_SECTION_POINT
    )
  })
}

export const replaceAnchorInUrlBySections = () => {
  const sections = Array.from(document.querySelectorAll('article'))

  const visibleSections = getSectionsOnScreen(sections)

  const currentSection = getCurrentSection(visibleSections)

  if (currentSection && window.history.pushState) {
    const urlAnchor = `#${currentSection.id}`

    if (window.location.hash === urlAnchor) {
      return null
    }

    window.history.replaceState(null, null, urlAnchor)

    return urlAnchor
  }

  return null
}

export const scrollToSectionId = (sectionId: string) => {
  if (!sectionId) {
    return
  }

  const elementByHash = document.getElementById(sectionId)

  window.scrollTo({
    behavior: 'smooth',
    top:
      window.scrollY +
      elementByHash.getBoundingClientRect().top -
      getHeaderHeight(),
  })
}

/* eslint-disable import/extensions */
const { defaultLng } = require('../i18n')
const { seasons } = require('../seasons.ts')

const getLocaleUrlPrefix = (locale) => {
  if (locale === defaultLng.code) {
    return ''
  }

  return `${locale}`
}

const getSeasonUrlSegment = (remoteSlug) => {
  const foundSeason = seasons.find((season) => season.remoteSlug === remoteSlug)

  return foundSeason && foundSeason.slug
}

module.exports = {
  getLocaleUrlPrefix,
  getSeasonUrlSegment,
}

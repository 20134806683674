import React from "react";
import styled from "styled-components";

enum Directions {
  left = "left",
  right = "right",
  top = "top",
  bottom = "bottom",
}

enum Rotates {
  right = "45deg",
  bottom = "135deg",
  left = "225deg",
  top = "315deg",
}

enum Shifts {
  right = "-20%, 0",
  bottom = "0, -20%",
  left = "20%, 0",
  top = "0, 20%",
}

interface IArrowIconProps {
  color?: string;
  rotate?: keyof typeof Directions;
  size?: number;
  className?: string;
}

interface IArrowProps {
  shift: string;
  rotate: string;
}

export const Arrow = styled.div<IArrowProps>`
  transition: all 0.25s ease-in-out;
  transform: translate(${(props) => props.shift})
    rotate(${(props) => props.rotate});
`;

export const UpArrow = styled.div`
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABmJLR0QA/wD/AP+gvaeTAAAAu0lEQVQYlWNgoDmQ2TNbT2bPbD10cSZkjtTOmRr/GBhr/jEw1kjtnKmBLMeIUDRPloH532Q2ZuaEL6z//rL9+L/oHzNz4QvHxAdwEyW2zRNlYPo35e/f32kPHBM/vLFJ/szIwJrC9OfvRPkdMyUZGBgYGJV2z+T/wci8kOH/37xnrumPkK2T3TVX6i/T/2lMfxiSmH4wMs9mZvxbjK6IgYGB4bFb8jNmxr/F/1j/T2eQObaKk2BIEKEGDgApy0LyToCe7gAAAABJRU5ErkJggg==");
  background-repeat: no-repeat;
  margin-top: 10px;
  background-size:10px 10px ;
  width: 20px;
  height: 20px;
`;

const ArrowIcon = ({ color, rotate, size, className }: IArrowIconProps) => (
  <Arrow
    className={`w-${size} h-${size} border-t-2 border-r-2 border-${color} ${className}`}
    rotate={Rotates[rotate]}
    shift={Shifts[rotate]}
  />
);

ArrowIcon.defaultProps = {
  // color: "red-500",
  rotate: Directions.right,
  size: 3,
  className: "",
};

export default ArrowIcon;
